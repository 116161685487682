import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import ProductColumnGenerator from '../components/ProductColumnGenerator'
import RequestButton from '../components/RequestButton'
import { CUSTOM_HEADER_COMPONENTS, BASE_BREADCRUMBS_PRODUCTS, DEFAULT_IMG_URL } from '../constants'

const ProductFamily = ({ pageContext }) => {
  const { hierarchyNode, data, parents } = pageContext
  return (
    <Layout
      fullWidth
      banners={{ data: hierarchyNode.banners, name: hierarchyNode.name }}
      pageTitle={hierarchyNode.name}
      customPageHeaderComponent={CUSTOM_HEADER_COMPONENTS[hierarchyNode.custom_header_widget]}
      breadcrumbs={[...BASE_BREADCRUMBS_PRODUCTS,
      ...parents.slice(1).map(parent => ({ name: parent.name, url: parent.url })),
      { name: hierarchyNode.name, url: hierarchyNode.url }]}
    >
      {
        data.webpageId ?
          <>
            <Seo title={`${hierarchyNode.name} | Typenex Medical`} description={hierarchyNode.meta_description} className="mb-2" />
            <div className="pt-4">
              <ProductColumnGenerator
                array={hierarchyNode.children.map(child => {
                  let img = (child.images?.length > 0 && child.images[0].image) || DEFAULT_IMG_URL
                  let alt_text = (child.images?.length > 0 && child.images[0].alt_text) || child.name
                  return ({
                    url: `/${parents.map(p => p.url).join('/')}/${hierarchyNode.url}/${child.url}`,
                    name: child.name,
                    img,
                    alt_text,
                    description: child.productData?.thumbnailDescription
                  })
                })}
              />
            </div>
            {
              hierarchyNode.sales_team && hierarchyNode.sales_team !== "Unassigned" &&
              <div className="text-center mt-5">
                <RequestButton node={hierarchyNode} />
              </div>
            }
          </>
          :
          <div className="m-5">
            <h2>Uh oh! webpageId "{hierarchyNode.webpage_id}" doesn't exist in webpages.json. </h2>
            <p>To fix this, please add "{hierarchyNode.webpage_id}" to webpages.json, along with all necessary page content.</p>
          </div>
      }
    </Layout>
  )
}

export default ProductFamily